import React, { Component } from 'react';
import {
    TextField,
    Label,
    TagPicker,
    TagItem,
    Text,
    DefaultButton,
    PrimaryButton,
    ChoiceGroup,
    Checkbox,
    Pivot,
    PivotItem,
    Dropdown
} from '@fluentui/react';
import { KundenDialog } from './common';

import styles from './Application.module.css';

class DataForm extends Component {
    constructor(props) {
        super(props);
        this.customerOptions = [{key: 0, text: 'Alle Kunden'}];
    }
    state = {
        new: true,
    };


    componentDidMount(){
        this.checkactive();
        this.customerOptions = [{key: 0, text: 'Alle Kunden'}];

        this.props.customer.forEach(item => {
            if(item.usergroup === 'users'){
                this.customerOptions.push({key: item.id, text: item.lastname + ', ' + item.firstname, item:item});
            }
        });

    }

    componentDidUpdate(prevProps) {
        this.customerOptions = [{key: '', text: 'Alle Kunden'}];
        this.props.customer.forEach(item => {
            if(item.usergroup === 'users'){
                this.customerOptions.push({key: item.id, text: item.lastname + ', ' + item.firstname, item:item});
            }
        });

    }


    /**
     * Setzt Education Wert in Localstoreage
     * @param {string} label
     * @returns {boolean}
     */
    initEducation(label) {
        let value = (localStorage.getItem('education') !== undefined && localStorage.getItem('education') !== null ?
        localStorage.getItem('education') + '|' + label : label)
        localStorage.setItem('education',value)
        return true;
    }

    /**
     * Entfernt Education Wert aus Localstoreage
     * @param {string} label
     * @returns {boolean}
     */
    clearEducation(label) {

        // Abrufen der Localstoreage
        let value = (localStorage.getItem('education') !== undefined && localStorage.getItem('education') !== null ?
            localStorage.getItem('education') : '')

        // Bilden des neuen Education Array
        let newEducation = [];

        // Altes Education wird durchlaufen und bereinigt
        for(let x = 0;x< value.split('|').length;x++) {
            if(value.split('|')[x] !== label) {
                newEducation.push(value.split('|')[x])
            }
        }

        // Bilden der neuen Ruckgabe Value
        let newValue = '';

        // Das neue Education wird durchlaufen
        for(let x=0;x<newEducation.length;x++) {
            // Leere Elemente werden bereinigt
            if (newEducation[x] !== '') {
                // Verbliebene Education werden wieder hinzugefügt
                newValue += newEducation[x] + '|';
            }
        }

        // Neues Education wird in der Localstoreage gespeichert
        localStorage.setItem('education',newValue)
        return false;
    }

    fillFields(item) {
        this.setState({customer: item.key})

        if(item.key !== 0) {
            let currCustomer = {};
            this.props.customer.forEach(entry => {
                if (entry.usergroup === 'users') {
                    if (item.key === entry.id) {
                        currCustomer = entry;
                    }
                }
            });
            this.props.data.name = currCustomer.lastname;
            this.props.data.forename = currCustomer.firstname;
            this.props.data.title = currCustomer.title;
            this.props.data.birthday = currCustomer.birthday;
            this.props.data.addressLine1 = currCustomer.street;
            this.props.data.addressPLZ = currCustomer.zip;
            this.props.data.addressCity = currCustomer.city;
            this.props.data.email = currCustomer.email;
        }
    }

    render() {
        return(
            <form className={styles.root} >
                <h1>{this.state.new === true ? 'Neuer Arbeitsschutz-Datensatz' : 'Arbeitsschutz-Datensatz'}</h1>
                {this.state.new === true &&
                <DefaultButton text='Kunde suchen' onClick={()=>this.setState({showKundenSelect:true})}/>}
                <KundenDialog
                    items={this.props.customer.filter(item => item.usergroup === 'users')}
                    hidden={!this.state.showKundenSelect}
                    title="Kunde suchen"
                    onSelect={item=>{
                        item.key = item.id;
                        this.fillFields(item);
                        this.setState({showKundenSelect:false})
                    }}
                    onClose={()=>this.setState({showKundenSelect:false})}
                />

                <Pivot id={'tablist'}>
                    <PivotItem
                        headerText="Antragsteller"
                    >
                        <TextField
                            label="Name"
                            value={this.props.data.name}
                            onChange={(e) => this.props.onChange('name', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Vorname"
                            value={this.props.data.forename}
                            onChange={(e) => this.props.onChange('forename', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Titel"
                            value={this.props.data.title}
                            onChange={(e) => this.props.onChange('title', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Geburtsname"
                            value={this.props.data.birthname}
                            onChange={(e) => this.props.onChange('birthname', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Staatsangehörigkeit"
                            value={this.props.data.nationality}
                            onChange={(e) => this.props.onChange('nationaltity', e.target.value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Geschlecht"
                            selectedKey={this.props.data.sex}
                            options={[
                                {
                                    key: "divers",
                                    text: "divers"
                                },
                                {
                                    key: "ohne Eintrag",
                                    text: "ohne Eintrag"
                                },
                                {
                                    key: "weiblich",
                                    text: "weiblich"
                                },
                                {
                                    key: "männlich",
                                    text: "männlich"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('sex', value.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Geburtstag"
                            value={this.props.data.birthday}
                            onChange={(e) => this.props.onChange('birthday', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Geburtsort"
                            value={this.props.data.birthplace}
                            onChange={(e) => this.props.onChange('birthplace', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Geburtsland"
                            value={this.props.data.birthcountry}
                            onChange={(e) => this.props.onChange('birthcountry', e.target.value)}
                            className={styles.field}
                        />
                        <Label>Adresse</Label>
                        <TextField
                            value={this.props.data.addressLine1}
                            onChange={(e) => this.props.onChange('addressLine1', e.target.value)}
                            className={styles.field}
                            placeholder="Straße + Hausnummer"
                        />
                        <TextField
                            value={this.props.data.addressLine2}
                            onChange={(e) => this.props.onChange('addressLine2', e.target.value)}
                            className={styles.field}
                            placeholder="Adresszusatz"
                        />
                        <TextField
                            value={this.props.data.addressPLZ}
                            onChange={(e) => this.props.onChange('addressPLZ', e.target.value)}
                            className={styles.field}
                            placeholder="PLZ"
                        />
                        <TextField
                            value={this.props.data.addressCity}
                            onChange={(e) => this.props.onChange('addressCity', e.target.value)}
                            className={styles.field}
                            placeholder="Stadt"
                        />
                        <TextField
                            label="Telefon"
                            value={this.props.data.phoneNumber}
                            onChange={(e) => this.props.onChange('phoneNumber', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="E-Mail-Adresse"
                            value={this.props.data.email}
                            onChange={(e) => this.props.onChange('email', e.target.value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Familienstand"
                            selectedKey={this.props.data.maritalStatus}
                            options={[
                                {
                                    key: "ledig",
                                    text: "ledig"
                                },
                                {
                                    key: "verheiratet",
                                    text: "verheiratet"
                                },
                                {
                                    key: "geschieden",
                                    text: "geschieden"
                                },
                                {
                                    key: "verwitwet",
                                    text: "verwitwet"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('maritalStatus', value.key)}
                            className={styles.field}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Gesundheit"
                    >
                        <ChoiceGroup
                            label="Sind bei Ihnen Gesundheitsstörungen anerkannt worden"
                            selectedKey={this.props.data.illnessAccredited}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('illnessAccredited', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.illnessAccredited === 'Ja' &&
                        <>
                        <TextField
                            label="Von welcher Stelle?"
                            value={this.props.data.illnessInstitution}
                            onChange={(e) => this.props.onChange('illnessInstitution', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data.illnessReference}
                            onChange={(e) => this.props.onChange('illnessReference', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Welche Störung?"
                            value={this.props.data.illnessType}
                            onChange={(e) => this.props.onChange('illnessType', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Haben Sie aktuell einen entsprechenden Antrag gestellt?"
                            selectedKey={this.props.data.illnessApplication}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('illnessApplication', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.illnessApplication === 'Ja' &&
                        <TextField
                            label="Bei welcher Stelle?"
                            value={this.props.data.illnessApplicationInstitution}
                            onChange={(e) => this.props.onChange('illnessApplicationInstitution', e.target.value)}
                            className={styles.field}
                        />
                        }
                        <ChoiceGroup
                            label="Haben Sie in den letzten 4 Jahren Leistungen zur medizinischen Rehabilitation (auch anderer Rehabilitationsträger, zum Beispiel Krankenkasse, Versorgungsamt, Unfallversicherungsträger) erhalten?"
                            selectedKey={this.props.data.rehab}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('rehab', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.rehab ==='Ja' &&
                        <>
                        <TextField
                            label="Von welcher Stelle?"
                            value={this.props.data.rehabInstitution}
                            onChange={(e) => this.props.onChange('rehabInstitution', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data.rehabReference}
                            onChange={(e) => this.props.onChange('rehabReference', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Beginn"
                            value={this.props.data.rehabStart}
                            onChange={(e) => this.props.onChange('rehabStart', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Ende"
                            value={this.props.data.rehabEnd}
                            onChange={(e) => this.props.onChange('rehabEnd', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Haben Sie unmittelbar vor diesem Rehabilitationsantrag bereits bei Ihrer Krankenkasse einen Antrag auf Mutter-Kind-Leistungen / Vater-Kind-Leistungen (Vorsorge oder Rehabilitation) gestellt beziehungsweise sind solche Leistungen zuvor verordnet worden?"
                            selectedKey={this.props.data.parentServices}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('parentServices', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.parentServices === 'Ja' &&
                        <>
                        <TextField
                            label="Datum"
                            value={this.props.data.parentServicesDate}
                            onChange={(e) => this.props.onChange('parentServicesDate', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Name der Krankenkasse"
                            value={this.props.data.parentServicesInsurance}
                            onChange={(e) => this.props.onChange('parentServicesInsurance', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data.parentServicesReference}
                            onChange={(e) => this.props.onChange('parentServicesReference', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Ist bei Ihnen eine Zuckerkrankheit bekannt?"
                            selectedKey={this.props.data.sugar}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('sugar', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Hatten Sie schon einmal Geschwüre oder offene Stellen an den Füßen?"
                            selectedKey={this.props.data.sugarUlcer}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('sugarUlcer', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Haben Sie Gefühlstörungen an den Füßen?"
                            selectedKey={this.props.data.feeling}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('feeling', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Sind bei Ihnen Durchblutungsstörungen der Beine bekannt?"
                            selectedKey={this.props.data.bloodCirculation}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('bloodCirculation', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Sind Sie schon einmal an den Füßen operiert worden?"
                            selectedKey={this.props.data.footOperation}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('footOperation', value.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Wennja, weshalb?"
                            value={this.props.data.footOperationReason}
                            onChange={(e) => this.props.onChange('footOperationReason', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Welche gesundheitlichen Probleme stehen bei Ihnen derzeit im Vordergrund?"
                            value={this.props.data.GesundheitProbleme}
                            onChange={(e) => this.props.onChange('GesundheitProbleme', e.target.value)}
                            className={styles.field}
                            multiline
                            autoAdjustHeight
                        />
                        
                        <ChoiceGroup
                            label="Wurde bei Ihnen eine Schwerbehinderung festgestellt oder sind Sie einem schwerbehinderten Menschen
                            gleichgestellt?"
                            selectedKey={this.props.data.Schwerb}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('Schwerb', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.Schwerb === 'Ja' &&
                        <>
                        <TextField
                            label="Art der Behinderung"
                            id={"handicapType"}
                            value={this.props.data.handicapType}
                            onChange={(e) => this.props.onChange('handicapType', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Grad der Behinderung"
                            value={this.props.data.handicapGrad}
                            onChange={(e) => this.props.onChange('handicapGrad', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Merkzeichen"
                            value={this.props.data.handicapMZ}
                            onChange={(e) => this.props.onChange('handicapMZ', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="seit"
                            value={this.props.data.handicapSince}
                            onChange={(e) => this.props.onChange('handicapSince', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }
                        
                        <ChoiceGroup
                            label="Sind Sie in den letzten 12 Monaten vom Medizinischen Dienst der Krankenkassen, von der Agentur für Arbeit oder
                            von einer anderen Stelle begutachtet worden?"
                            selectedKey={this.props.data.MDK_AA_Accredited}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('MDK_AA_Accredited', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.MDK_AA_Accredited === 'Ja' &&
                        <>
                        <TextField
                            label="Wann und on welcher Stelle?"
                            value={this.props.data.MDK_AA_AccreditedInstitution}
                            onChange={(e) => this.props.onChange('MDK_AA_AccreditedInstitution', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Nehmen Sie an arbeitsmedizinischen Vorsorgeuntersuchungen teil?"
                            selectedKey={this.props.data.arbMedVU}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('arbMedVU', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.MDK_AA_Accredited === 'Ja' &&
                        <>
                        <TextField
                            label="wegen?"
                            value={this.props.data.arbMedVUGrund}
                            onChange={(e) => this.props.onChange('arbMedVUGrund', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Ist ein Betriebsarzt / Werksarzt vorhanden?"
                            selectedKey={this.props.data.BetriebsarztVorh}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => {
                                this.props.onChange('BetriebsarztVorh', value.key)
                                this.props.onChange('BetriebsarztWeitergabe', value.key)
                                
                            }}
                            className={styles.field}
                        />
                        {this.props.data.BetriebsarztVorh === 'Ja' &&
                        <>
                        <TextField
                            label="Name, Anschrift und Telefon des Arztes"
                            value={this.props.data.Betriebsarzt}
                            onChange={(e) => this.props.onChange('Betriebsarzt', e.target.value)}
                            className={styles.field}
                        />
                        
                        <ChoiceGroup
                            label="Ich bin damit einverstanden, dass sich der Rentenversicherungsträger mit dem Betriebsarzt / Werksarzt oder dem
                            Betrieb hinsichtlich einer Prüfung des Arbeitsplatzes und gegebenenfalls weiterer für erforderlich gehaltener
                            Leistungen in Verbindung setzt (diese Erklärung kann jederzeit widerrufen werden)."
                            selectedKey={this.props.data.BetriebsarztWeitergabe}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('BetriebsarztWeitergabe', value.key)}
                            className={styles.field}
                        />
                        </>
                        }
                    </PivotItem>
                    <PivotItem
                        headerText="Ausbildung"
                    >
                        <div className={styles.field} id={'education'}>
                            <label className={styles.multiselectLabel}>Welche Schulen haben Sie besucht?</label>
                           <Checkbox className={styles.multiselectOption} label="Hauptschule / Polytechnische Oberschule (8 Klassen)" checked={this.props.data.hauptschule === true}
                           onChange={() => this.props.onChange('hauptschule', this.props.data.hauptschule === true ?
                              this.clearEducation('hauptschule') : this.initEducation('hauptschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Realschule / Polytechnische Oberschule (10 Klassen)" checked={this.props.data.realschule === true}
                             onChange={() => this.props.onChange('realschule', this.props.data.realschule === true ?
                              this.clearEducation('realschule') : this.initEducation('realschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Gymnasium / Erweiterte Oberschule" checked={this.props.data.gymnasium === true}
                            onChange={() => this.props.onChange('gymnasium', this.props.data.gymnasium === true ?
                              this.clearEducation('gymnasium') : this.initEducation('gymnasium') )}/>
                            <Checkbox className={styles.multiselectOption} label="Gesamtschule" checked={this.props.data.gesamtschule === true}
                             onChange={() => this.props.onChange('gesamtschule', this.props.data.gesamtschule === true ?
                              this.clearEducation('gesamtschule') : this.initEducation('gesamtschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Berufsschule / Berufsfachschule / Berufsaufbauschule" checked={this.props.data.berufsschule === true}
                            onChange={() => this.props.onChange('berufsschule', this.props.data.berufsschule === true ?
                              this.clearEducation('berufsschule') : this.initEducation('berufsschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Fachschule" checked={this.props.data.fachschule === true}
                            onChange={() => this.props.onChange('fachschule', this.props.data.fachschule === true ?
                              this.clearEducation('fachschule') : this.initEducation('fachschule') )}/>
                            <Checkbox className={styles.multiselectOption} label="Fachoberschule" checked={this.props.data.fachoberschule === true}
                            onChange={() => this.props.onChange('fachoberschule', this.props.data.fachoberschule === true ?
                              this.clearEducation('fachoberschule') : this.initEducation('fachoberschule') )}  />
                            <Checkbox className={styles.multiselectOption} label="Fachhochschule" checked={this.props.data.fachhochschule === true}
                            onChange={() => this.props.onChange('fachhochschule', this.props.data.fachhochschule === true ?
                              this.clearEducation('fachhochschule') : this.initEducation('fachhochschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Hochschule" checked={this.props.data.hochschule === true}
                            onChange={() => this.props.onChange('hochschule', this.props.data.hochschule === true ?
                              this.clearEducation('hochschule') : this.initEducation('hochschule') )} />

                            {/*{this.buildCheckboxEducation()}    */}
                        </div>
                        {this.props.data.hauptschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Hauptschule?"
                            value={this.props.data.hauptschuleTime}
                            onChange={(e) => this.props.onChange('hauptschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.realschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Realschule?"
                            value={this.props.data.realschuleTime}
                            onChange={(e) => this.props.onChange('realschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.gymnasium === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf dem Gymnasium?"
                            value={this.props.data.gymnasiumTime}
                            onChange={(e) => this.props.onChange('gymnasiumTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.gesamtschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Gesamtschule?"
                            value={this.props.data.gesamtschuleTime}
                            onChange={(e) => this.props.onChange('gesamtschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.berufsschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Berufsschule?"
                            value={this.props.data.berufsschuleTime}
                            onChange={(e) => this.props.onChange('berufsschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.fachschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Fachschule?"
                            value={this.props.data.fachschuleTime}
                            onChange={(e) => this.props.onChange('fachschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.fachoberschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Fachoberschule?"
                            value={this.props.data.fachoberschuleTime}
                            onChange={(e) => this.props.onChange('fachoberschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.fachhochschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Fachhochschule?"
                            value={this.props.data.fachhochschuleTime}
                            onChange={(e) => this.props.onChange('fachhochschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data.hochschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Hochschule?"
                            value={this.props.data.hochschuleTime}
                            onChange={(e) => this.props.onChange('hochschuleTime', e.target.value)}
                            className={styles.field}
                        />
                        }
                        <TextField
                            label="Erreichte Schulabschlüsse oder Art der abgelegten Prüfungen"
                            value={this.props.data.graduation}
                            onChange={(e) => this.props.onChange('graduation', e.target.value)}
                            className={styles.field} 
                            multiline={true}
                        />
                        <TextField
                            label="Welche Berufsausbildung haben Sie?"
                            id={"vocationalTraining"}
                            value={this.props.data.vocationalTraining}
                            onChange={(e) => this.props.onChange('vocationalTraining', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Von wann bis wann war Ihre Berufsausbildung?"
                            value={this.props.data.vocationalTrainingTime}
                            onChange={(e) => this.props.onChange('vocationalTrainingTime', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Mit welcher Prüfungsart wurde Ihre Berufsausbildung abgeschlossen?"
                            value={this.props.data.vocationalTrainingExam}
                            onChange={(e) => this.props.onChange('vocationalTrainingExam', e.target.value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Hatten Sie schon mal eine Umschulung?"
                            selectedKey={this.props.data.retraining}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('retraining', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.retraining === 'ja' &&
                        <>
                        <TextField
                            label="Umschulungsberuf"
                            id={"retraining"}
                            value={this.props.data.retrainingType}
                            onChange={(e) => this.props.onChange('retrainingType', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Umschulungszeitraum"
                            value={this.props.data.retrainingTime}
                            onChange={(e) => this.props.onChange('retrainingTime', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aus welchem Grund erfolgte die Umschulung?"
                            value={this.props.data.retrainingReason}
                            onChange={(e) => this.props.onChange('retrainingReason', e.target.value)}
                            className={styles.field}
                            multiline={true}
                        />
                        <ChoiceGroup
                            label="Wurde die Umschulung erfolgreich beendet?"
                            selectedKey={this.props.data.retrainingSuccess}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('retrainingSuccess', value.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Wer war der Kostenträger der Umschulung?"
                            value={this.props.data.retrainingInstitution}
                            onChange={(e) => this.props.onChange('retrainingInstitution', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen des Kostenträgers"
                            value={this.props.data.retrainingReference}
                            onChange={(e) => this.props.onChange('retrainingReference', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Wo erfolgte die Umschulung?"
                            value={this.props.data.retrainingLocation}
                            onChange={(e) => this.props.onChange('retrainingLocation', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }

                    </PivotItem>
                    <PivotItem
                        headerText="Beruf"
                    >
                        <TextField
                            label="Zuletzt ausgeübte Erwerbstätigkeit / Beruf"
                            value={this.props.data.profession}
                            onChange={(e) => this.props.onChange('profession', e.target.value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Derzeitige Stellung im Beruf / Erwerbsleben"
                            selectedKey={this.props.data.position}
                            options={[
                                {
                                    key: "nicht erwerbstätig",
                                    text: "nicht erwerbstätig"
                                },
                                {
                                    key: "Auszubildender",
                                    text: "Auszubildender"
                                },
                                {
                                    key: "ungelernter Arbeiter",
                                    text: "ungelernter Arbeiter"
                                },
                                {
                                    key: "angelernter Arbeiter in anerkanntem Anlernberuf",
                                    text: "angelernter Arbeiter in anerkanntem Anlernberuf"
                                },
                                {
                                    key: "Facharbeiter",
                                    text: "Facharbeiter"
                                },
                                {
                                    key: "Meister, Polier",
                                    text: "Meister, Polier"
                                },
                                {
                                    key: "Angestellter",
                                    text: "Angestellter"
                                },
                                {
                                    key: "Beamter oder DO-Angestellter, Versorgungsempfänger im Sinne des Beamtenrechts",
                                    text: "Beamter oder DO-Angestellter, Versorgungsempfänger im Sinne des Beamtenrechts"
                                },
                                {
                                    key: "Selbständiger",
                                    text: "Selbständiger"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('position', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeit vor Antragstellung oder vor aktueller Arbeitsunfähigkeit"
                            selectedKey={this.props.data.employementType}
                            options={[
                                {
                                    key: "nicht erwerbstätig",
                                    text: "nicht erwerbstätig"
                                },
                                {
                                    key: "Ganztagsarbeit ohne Wechselschicht oder Akkord oder Nachtschicht",
                                    text: "Ganztagsarbeit ohne Wechselschicht oder Akkord oder Nachtschicht"
                                },
                                {
                                    key: "Ganztagsarbeit mit Wechselschicht oder Akkord",
                                    text: "Ganztagsarbeit mit Wechselschicht oder Akkord"
                                },
                                {
                                    key: "Ganztagsarbeit mit Nachtschicht",
                                    text: "Ganztagsarbeit mit Nachtschicht"
                                },
                                {
                                    key: "Teilzeitarbeit, weniger als die Hälfte der üblichen Arbeitszeit",
                                    text: "Teilzeitarbeit, weniger als die Hälfte der üblichen Arbeitszeit"
                                },
                                {
                                    key: "Teilzeitarbeit, mindestens die Hälfte der üblichen Arbeitszeit",
                                    text: "Teilzeitarbeit, mindestens die Hälfte der üblichen Arbeitszeit"
                                },
                                {
                                    key: "ausschließlich Tätigkeit als Hausfrau oder Hausmann",
                                    text: "ausschließlich Tätigkeit als Hausfrau oder Hausmann"
                                },
                                {
                                    key: "arbeitslos gemeldet",
                                    text: "arbeitslos gemeldet"
                                },
                                {
                                    key: "Heimarbeit",
                                    text: "Heimarbeit"
                                },
                                {
                                    key: "Beschäftigung in einer Werkstatt für behinderte Menschen",
                                    text: "Beschäftigung in einer Werkstatt für behinderte Menschen"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('employementType', value.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Name und Anschrift des Arbeitgebers"
                            value={this.props.data.employer}
                            onChange={(e) => this.props.onChange('employer', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Beschäftigungsbegin"
                            value={this.props.data.employementStart}
                            onChange={(e) => this.props.onChange('employementStart', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Anzahl der Mitarbeiter im Betrieb"
                            value={this.props.data.employees}
                            onChange={(e) => this.props.onChange('employees', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Art des Betriebes"
                            value={this.props.data.employerType}
                            onChange={(e) => this.props.onChange('employerType', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="genaue Tätigkeitsbezeichnung"
                            value={this.props.data.jobDescription}
                            onChange={(e) => this.props.onChange('jobDescription', e.target.value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Ist das Beschäftigungsverhältnis befristet?"
                            selectedKey={this.props.data.temporaryEmployement}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('temporaryEmployement', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.temporaryEmployement === 'Ja' &&
                        <TextField
                            label="Befristung bis"
                            value={this.props.data.temporaryEmployementEnd}
                            onChange={(e) => this.props.onChange('temporaryEmployementEnd', e.target.value)}
                            className={styles.field}
                        />
                        }
                        <ChoiceGroup
                            label="Ist das Beschäftigungsverhältnis gekündigt?"
                            selectedKey={this.props.data.terminatedEmployement}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('terminatedEmployement', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.terminatedEmployement === 'Ja' &&
                        <TextField
                            label="Befristung bis"
                            value={this.props.data.terminatedEmployementEnd}
                            onChange={(e) => this.props.onChange('terminatedEmployementEnd', e.target.value)}
                            className={styles.field}
                        />
                        }
                        <Text variant='large'>Arbeitshaltung</Text>
                        <ChoiceGroup
                            label="Arbeitshaltung stehend"
                            selectedKey={this.props.data.standing}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('standing', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeitshaltung gehend"
                            selectedKey={this.props.data.walking}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('walking', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeitshaltung sitzend"
                            selectedKey={this.props.data.sitting}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('sitting', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeitshaltung gebückt"
                            selectedKey={this.props.data.bentover}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('bentover', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arme über Brusthöhe"
                            selectedKey={this.props.data.armsAboveChest}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('armsAboveChest', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="kniehend / hockend"
                            selectedKey={this.props.data.kneeling}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('kneeling', value.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="auf Gerüsten / Leitern"
                            selectedKey={this.props.data.onLadders}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange('onLadders', value.key)}
                            className={styles.field}
                        />
                        <Text variant='large'>Heben/ Tragen</Text>
                        <TextField
                            label="Art der zu hebenden Lasten"
                            value={this.props.data.liftingWeights}
                            onChange={(e) => this.props.onChange('liftingWeights', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="häuzfig zu hebende Lasten"
                            value={this.props.data.offtenWeights}
                            onChange={(e) => this.props.onChange('offtenWeights', e.target.value)}
                            className={styles.field}
                            suffix="kg"
                            type="number"
                        />
                        <TextField
                            label="max. zu hebende Lasten"
                            value={this.props.data.maxWeights}
                            onChange={(e) => this.props.onChange('maxWeights', e.target.value)}
                            className={styles.field}
                            suffix="kg"
                            type="number"
                        />
                        <ChoiceGroup
                            label="Sind technische Hebehilfsmittel vorhanden?"
                            selectedKey={this.props.data.technicalLiftingAids}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('technicalLiftingAids', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.technicalLiftingAids === 'Ja' &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data.technicalLiftingAidsText}
                            onChange={(e) => this.props.onChange('technicalLiftingAidsText', e.target.value)}
                            className={styles.field}
                        />
                        }
                        <TextField
                            label="weitere Bemerkung"
                            value={this.props.data.LiftComment}
                            onChange={(e) => this.props.onChange('LiftComment', e.target.value)}
                            className={styles.field}
                        />
                        
                        <Text variant='large'>Arbeitsorganisation</Text>
                        <TextField
                            label="wöchentliche Arbeitszeit"
                            value={this.props.data.workingTime}
                            onChange={(e) => this.props.onChange('workingTime', e.target.value)}
                            className={styles.field}
                        />
                     {["Ganztagsarbeit","Teilzeitarbeit", "andere-Arbeitszeitmodelle","regelmäßige Frühschicht / Spätschicht","regelmäßige-Dreischicht"].map(key=>
                        <div key={key}>
                        <ChoiceGroup
                            label={key.replace('-',' ')}
                            selectedKey={this.props.data[key]}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange(key, value.key)}
                            className={styles.field}
                        />
                        {this.props.data["andere-Arbeitszeitmodelle"] === 'Ja' && key==="andere-Arbeitszeitmodelle" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data['andere-ArbeitszeitmodelleText']}
                            onChange={(e) => this.props.onChange('andere-ArbeitszeitmodelleText', e.target.value)}
                            className={styles.field}
                        />
                        }
                        </div>)}

                        <Text variant='large'>Äußere Einflüsse</Text>
                     {["Kälte, Zugluft, Nässe","Hitze", "starke-Staubentwicklung","starker-Laerm","Lärmschutz-muss-getragen-werden","hautreizende-Stoffe","atemwegsreizende-Stoffe","überwiegend-witterungsgeschützt"].map(key=>
                        <div key={key}>
                        <ChoiceGroup
                            label={key.replace('-',' ').replace('-',' ').replace('ä','ae').replace('u','ue')}
                            selectedKey={this.props.data[key]}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange(key, value.key)}
                            className={styles.field}
                        />
                        {this.props.data["hautreizende-Stoffe"] === 'Ja' && key==="hautreizende-Stoffe" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data['hautreizende-Stoffe-Text']}
                            onChange={(e) => this.props.onChange('hautreizende-Stoffe-Text', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data["atemwegsreizende-Stoffe"] === 'Ja' && key==="atemwegsreizende-Stoffe" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data['atemwegsreizende-Stoffe-Text']}
                            onChange={(e) => this.props.onChange('atemwegsreizende-Stoffe-Text', e.target.value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data["andere-Arbeitszeitmodelle"] === 'Ja' && key==="andere-Arbeitszeitmodelle" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data.technicalLiftingAidsText}
                            onChange={(e) => this.props.onChange('andere-ArbeitszeitmodelleText', e.target.value)}
                            className={styles.field}
                        />
                        }
                        </div>)}
                    </PivotItem>
                    <PivotItem
                        headerText="Krankenkasse"
                    >
                        <TextField
                            label="Name"
                            value={this.props.data.healthInsuranceName}
                            onChange={(e) => this.props.onChange('healthInsuranceName', e.target.value)}
                            className={styles.field}
                        />
                        <Label>Adresse</Label>
                        <TextField
                            value={this.props.data.healthInsuranceAddressLine1}
                            onChange={(e) => this.props.onChange('healthInsuranceAddressLine1', e.target.value)}
                            className={styles.field}
                            placeholder="Straße + Hausnummer"
                        />
                        <TextField
                            value={this.props.data.healthInsuranceAddressLine2}
                            onChange={(e) => this.props.onChange('healthInsuranceAddressLine2', e.target.value)}
                            className={styles.field}
                            placeholder="Adresszusatz"
                        />
                        <TextField
                            value={this.props.data.healthInsuranceAddressPLZ}
                            onChange={(e) => this.props.onChange('healthInsuranceAddressPLZ', e.target.value)}
                            className={styles.field}
                            placeholder="PLZ"
                        />
                        <TextField
                            value={this.props.data.healthInsuranceAddressCity}
                            onChange={(e) => this.props.onChange('healthInsuranceAddressCity', e.target.value)}
                            className={styles.field}
                            placeholder="Stadt"
                        />
                        <TextField
                            label="Telefon"
                            value={this.props.data.healthInsurancePhoneNumber}
                            onChange={(e) => this.props.onChange('healthInsurancePhoneNumber', e.target.value)}
                            className={styles.field}
                        />
                        <ChoiceGroup 
                            label="Art der Krankenkasse" 
                            selectedKey={this.props.data.healthInsuranceType} 
                            options={[
                                {
                                    key: 'Gesetzliche Krankenkasse',
                                    text: 'Gesetzliche Krankenkasse',
                                },
                                {
                                    key: 'Private Krankenversicherung',
                                    text: 'Private Krankenversicherung',
                                }
                            ]} 
                            onChange={(e, value) => this.props.onChange('healthInsuranceType', value.key)} 
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Ärztin/Arzt"
                    >
                        <TextField
                            label="Name"
                            value={this.props.data.doctorName}
                            onChange={(e) => this.props.onChange('doctorName', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Vorname"
                            value={this.props.data.doctorForename}
                            onChange={(e) => this.props.onChange('doctorForename', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Titel"
                            value={this.props.data.doctorTitle}
                            onChange={(e) => this.props.onChange('doctorTitle', e.target.value)}
                            className={styles.field}
                        />
                        <Label>Adresse</Label>
                        <TextField
                            value={this.props.data.doctorAddressLine1}
                            onChange={(e) => this.props.onChange('doctorAddressLine1', e.target.value)}
                            className={styles.field}
                            placeholder="Straße + Hausnummer"
                        />
                        <TextField
                            value={this.props.data.doctorAddressLine2}
                            onChange={(e) => this.props.onChange('doctorAddressLine2', e.target.value)}
                            className={styles.field}
                            placeholder="Adresszusatz"
                        />
                        <TextField
                            value={this.props.data.doctorAddressPLZ}
                            onChange={(e) => this.props.onChange('doctorAddressPLZ', e.target.value)}
                            className={styles.field}
                            placeholder="PLZ"
                        />
                        <TextField
                            value={this.props.data.doctorAddressCity}
                            onChange={(e) => this.props.onChange('doctorAddressCity', e.target.value)}
                            className={styles.field}
                            placeholder="Stadt"
                        />
                        <TextField
                            label="Telefon"
                            value={this.props.data.doctorPhoneNumber}
                            onChange={(e) => this.props.onChange('doctorPhoneNumber', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="E-Mail-Adresse"
                            value={this.props.data.doctorEmail}
                            onChange={(e) => this.props.onChange('doctorEmail', e.target.value)}
                            className={styles.field}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Sonstiges"
                    >
                                                <TextField
                            label="Rententräger"
                            value={this.props.data.pensionHolder}
                            onChange={(e) => this.props.onChange('pensionHolder', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Rentenversicherungsnummer"
                            value={this.props.data.pensionNumber}
                            onChange={(e) => this.props.onChange('pensionNumber', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Haben sie schon mal im Ausland gearbeitet und dort Steuern gezahlt?"
                            value={this.props.data.foreignCountry}
                            onChange={(e) => this.props.onChange('foreignCountry', e.target.value)}
                            className={styles.field}
                        />

                        <h2>Angaben zur Gesundheit</h2>

                        <TextField
                            label="Hat in letzter Zeit die Krankenkasse den Medizinischen Dienst für Sie beauftragt?"
                            value={this.props.data.mdk}
                            onChange={(e) => this.props.onChange('mdk', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Nehmen Sie an betriebsärztlichen Untersuchungen teil?"
                            value={this.props.data.investigation}
                            onChange={(e) => this.props.onChange('investigation', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Waren Sie in den letzten 6 Monaten ernsthaft erkrankt?"
                            value={this.props.data.disease}
                            onChange={(e) => this.props.onChange('disease', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Haben Sie einen anerkannten Scherbehinderungsgrad?"
                            value={this.props.data.handicap}
                            onChange={(e) => this.props.onChange('handicap', e.target.value)}
                            className={styles.field}
                        />

<ChoiceGroup
                            label="Haben Sie aktuell einen Antrag auf Leistungen zur Teilhabe am Arbeitsleben bei einem anderen Leistungsträger
                            gestellt (zum Beispiel Agentur für Arbeit, Berufsgenossenschaft, Integrationsamt)?"
                            selectedKey={this.props.data.AntragTeilhabe}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange('AntragTeilhabe', value.key)}
                            className={styles.field}
                        />
                        {this.props.data.AntragTeilhabe === 'Ja' &&
                        <>
                        <TextField
                            label="Von welcher Stelle?"
                            value={this.props.data.AntragTeilhabeInstitution}
                            onChange={(e) => this.props.onChange('AntragTeilhabeInstitution', e.target.value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data.AntragTeilhabeReference}
                            onChange={(e) => this.props.onChange('AntragTeilhabeReference', e.target.value)}
                            className={styles.field}
                        />
                        </>
                        }                        

                        <h2>Angaben zur schul. Bildung</h2>
                        <TextField
                            label="Schulbildung"
                            value={Prefillinput(this.props.data,this.props.data.education,'education')}
                            onChange={(e) => this.props.onChange('education', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Berufsausbildung"
                            value={Prefillinput(this.props.data,this.props.data.vocationalTraining,'vocationalTraining')}
                            onChange={(e) => this.props.onChange('vocationalTraining', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Studium"
                            value={this.props.data.study}
                            onChange={(e) => this.props.onChange('study', e.target.value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Umschulung"
                            value={Prefillinput(this.props.data,this.props.data.retraining,'retraining')}
                            onChange={(e) => this.props.onChange('retraining', e.target.value)}
                            className={styles.field}
                        />
                    </PivotItem>
                </Pivot>

                <div className={styles.footer}>
                    <PrimaryButton id={'forward'} text={'Weiter'} onClick={() => this.active()}  />
                </div>
            </form>
        )
    }

    _selectItem(){
        const id = this.props.match.params.id;
        const items = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedItem = items.filter(item => item.id === parseInt(id));
            if(selectedItem.length > 0){
                this.setState({
                    new: false,
                    item: selectedItem[0]
                });
            }
        }
    }

    /**
     * Init Button effect for Privot Tab Button
     */
    active() {
        let id;
        let next;
        let last;
        let AllButton = document.getElementById('tablist').childNodes[0].querySelectorAll('button')
        for(let x=0;x<AllButton.length;x++) {
            if (AllButton[x].classList.contains('is-selected')) {
                id = AllButton[x].getAttribute('id').split('-')[0]
                next = x+1;
                if(AllButton[x].getAttribute('name') === 'Sonstiges') {
                    last = true;
                } else {
                    last = false;
                }

            } else {

            }
        }
        if(last !== true) {
            document.getElementById(id + "-Tab" + next).click()
        } else {
            this.props.onSave()
        }
    }

    /**
     * Build Button text for Privot Tabs
     */
    checkactive() {

        localStorage.setItem('education','')

        let AllButton = document.getElementById('tablist').childNodes[0].querySelectorAll('button')

        for(let x=0;x<AllButton.length;x++){
            if(AllButton[x].getAttribute('name') === 'Sonstiges') {
                AllButton[x].addEventListener('click',  function() {
                    document.getElementById('forward').innerHTML = 'übernehmen';
                });
            }

            else if(AllButton[x].getAttribute('name') === 'Ausbildung') {
                AllButton[x].addEventListener('click',  function() {
                    Prefillinput();
                    document.getElementById('forward').innerHTML = 'Weiter'});
            }
            else {
                AllButton[x].addEventListener('click',  function() {
                    document.getElementById('forward').innerHTML = 'Weiter'});

            }
        }
    }
}

export default DataForm;

/**
 * Gleich die Eingabe aus Reiter Ausbildung ab und befüllt damit Reiter sonstiges vor
 * @param {object} input
 * @param {string} target
 * @param {string} value
 * @returns {string}
 * @constructor
 */
const Prefillinput = (input,target,value,state) => {

    if(target !== '') {
        switch (value) {
            case 'education':
                    target = localStorage.getItem('education');
                break;
            case 'vocationalTraining':
                if (document.getElementById('vocationalTraining') !== undefined &&
                    document.getElementById('vocationalTraining') !== null) {
                    target = document.getElementById('vocationalTraining').getAttribute('value');
                }
                break;
            case 'retraining':
                if (document.getElementById('retraining') !== undefined &&
                    document.getElementById('retraining') !== null) {
                    target = document.getElementById('retraining').getAttribute('value');
                }
                break;
            default:
        }


        return target;
    }
    return '';
}