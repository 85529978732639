import React, {Component} from 'react';

import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import { DetailsList, SelectionMode ,DetailsListLayoutMode,  ConstrainMode,  DetailsRow  } from '@fluentui/react/lib/DetailsList';

import './ListView.css';

import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';

export class ListViewRow extends Component{
  render(){
    return <DetailsRow {...this.props} />
  }

}
export class ListViewSticky extends Sticky{

}

export class ListView extends Component {
	 
	render() {
		return (
		<div  className={'ListViewContainer '+(this.props.className? this.props.className :'')} style={this.props.style}>

    <ScrollablePane>
        <DetailsList
          compact={true}
          
          layoutMode={DetailsListLayoutMode.fixedColumns}
          constrainMode={ConstrainMode.unconstrained}
          selectionMode={SelectionMode.single}
          onRenderDetailsHeader={(props, defaultRender)=> {
            return (
              <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender(props)}
              </Sticky>
            );
          }}
          
          onColumnHeaderClick={this.props.onColumnHeaderClick}
          
          {...this.props}
        />
      {this.props.footer}
    </ScrollablePane>
   </div>);
	}
}

export default ListView;