import React, {Component} from 'react'; 
import {ListView} from '../common/index';

import { Dialog, DialogType, DialogFooter, Selection,DetailsListLayoutMode, DefaultButton, SearchBox} from '@fluentui/react/lib/index';

import  styles from './KundenDialog.module.css'
export class KundenDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sort:{
				desc:false,
				by:'Firma'
			},
			search:""
		}
		this.mount = false;
		this._selection = new Selection({
			onSelectionChanged: () => {
			  console.log("selch")
				let sel = this._selection.getSelection();
				  if (sel.length === 1){
					this.props.onSelect(sel[0]);
				} else {
				}
			}
		});
	}
	componentWillUnmount(){
		this.mount =false;
	}

	componentDidUpdate(prevProps, prevState) {
		
		if ( typeof(this.props.selected) !== 'undefined' ) {
			if (prevState.selectedItem===null && this.props.selected!==null){
				this.setState({selectedItem:this.props.selected})
			}
		}
	}
	
	render() {
		var value = [];
		/*
		for (var p = 0, c=this.props.items.length; p<c; p++){
			if (this.state.selectedItem!==null && this.state.selectedItem!==[]){
				if (this.props.items[p].id === this.state.selectedItem.id){
					value.push(this.props.items[p]);
				}
			}
		} /** */
		return (
				<Dialog
					hidden={this.props.hidden}
					dialogContentProps={{
						type: DialogType.normal,
						title: <div style={{display:'flex', justifyContent:'space-between'}}>
							<div>{this.props.title}</div>
							<SearchBox placeholder="Kunden suchen"
							 onChange={(e,newValue)=>this.setState({search: typeof(newValue)==='undefined'?'':newValue})}
							 />
						</div>
					}}
					modalProps={{
						titleAriaId: 'myLabelId',
						subtitleAriaId: 'mySubTextId',
						isBlocking: true,
						containerClassName: styles.Dialog
					}}
					onDismiss={() => {
						this.props.onClose(false);
					}} style={{minWidth:"400px"}}
					>
					{<div className="flex-column">
						<ListView 
							items={this.props.items.filter(item=>{
								if(String(this.state.search).trim()!=="") {
									let res = item.lastname.toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase()) >= 0;
									res |= item.firstname.toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase()) >= 0;
									res |= String(JSON.stringify(item.Ansprechparter)).toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase()) >= 0;
									res |= String(JSON.stringify(item.Anschrift)).toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase()) >= 0;
									return res;
								}
								return true;
							}).sort((a, b) =>{          
							   if (a.lastname === b.lastname) {
								  // Price is only important when cities are the same
								  return b.firstname - a.firstname;
							   }
							   return a.lastname > b.lastname ? 1 : -1;
							})}
							style={{minHeight:'300px'}}
							layoutMode={DetailsListLayoutMode.justified}
							columns={
								[
									{
										key: 'column2',
										name: 'Name',
										fieldName: 'lastname',
										minWidth: 120,
										maxWidth: 300,
										isResizable: true,
										isSorted: (this.state.sort.by === 'Firma'),
										isSortedDescending: (this.state.sort.desc),
										isPadded: true,
										isMultiline:true
							  } ,
									{
										key: 'column3',
										name: 'Vorname',
										fieldName: 'firstname',
										minWidth: 120,
										maxWidth: 200,
										isResizable: true,
										isMultiline:true,
										isSorted: (this.state.sort.by === 'Geschaeftsfuehrer'),
										isSortedDescending: (this.state.sort.desc),
										isPadded: true,
										isSortable:false
							  }
								]}
							selection={this._selection}
						/>
						
					</div>}
					<DialogFooter>
						{/*<PrimaryButton onClick={(e)=>{
							this.props.onSelect(this.state.selectedItem);
						}} text="Übernehmen"
						disabled={this.state.selectedItem===null} /> /** */}
						<DefaultButton onClick={(e)=>{
							this.props.onClose(false);
						}} text="abbrechen" />
					</DialogFooter>
					</Dialog>
			
		)
		;
	}
}
